import React, { useEffect, useState } from "react";
import { Box, Button, Flex, MenuItem, Image, Menu, MenuButton, MenuList, Avatar, Badge, Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import { useTheme } from "@chakra-ui/react";
import { useAuth } from "./AuthContext";
import { ChevronDownIcon } from "@chakra-ui/icons";
import UserProfile from "./UserProfile";
import whApiService from "./services/apiWhService";

function Header() {
    const navigate = useNavigate();
    const theme = useTheme();
    const { currentUser } = useAuth(); // Use the useAuth hook
    const [subscriptionStatus, setSubscriptionStatus] = useState({ status: "inactive", validUntil: null });
    const [isLoading, setIsLoading] = useState(true); // Loading state for fetching subscription
    const [isExtending, setIsExtending] = useState(false); // Loading state for extending license

    // Function to fetch subscription data
    const fetchSubscriptionData = async () => {
        if (currentUser) {
            setIsLoading(true);
            const userId = currentUser.uid;
            try {
                const userData = await whApiService.getUserData(userId);
                if (userData) {
                    setSubscriptionStatus({ status: userData.subscriptionDetails.status, validUntil: userData.subscriptionDetails.validUntil });
                } else {
                    setSubscriptionStatus({ status: 'inactive', validUntil: null }); // Set default state if no data
                }
            } catch (error) {
                console.error("Error fetching subscription data:", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    };

    // useEffect to fetch subscription data when currentUser changes
    useEffect(() => {
        fetchSubscriptionData();
    }, [currentUser]);

    // Function to handle license extension
    const handleExtendLicense = async () => {
        if (!currentUser) return;
        try {
            setIsExtending(true);
            const userId = currentUser.uid;
            const response = await whApiService.extendLicense(userId);
            // Assuming a 200 status means success
            console.log("License extended for user:", response);
            // Refresh subscription status after extending license
            await fetchSubscriptionData();
        } catch (error) {
            console.error("Error while extending license:", error);
        } finally {
            setIsExtending(false);
        }
    };

    // Function to render the subscription badge
    const renderSubscriptionBadge = () => {
        if (isLoading) {
            return <Spinner size="sm" ml={2} />;
        } 

        if (!subscriptionStatus) {
            // If subscriptionStatus is null or undefined, return nothing or a placeholder
            return null;
        }

        if (subscriptionStatus.status === 'active') {
            return <Badge colorScheme="green" ml={2}>Valid until {subscriptionStatus.validUntil}</Badge>;
        } else {
            return (
                <>
                    <Badge colorScheme="red" ml={2}>No premium features</Badge>
                    <Badge
                        colorScheme="blue"
                        ml={2}
                        cursor="pointer"
                        onClick={handleExtendLicense}
                    >
                        {isExtending ? <Spinner size="xs" /> : "Extend license"}
                    </Badge>
                </>
            );
        }
    };

    return (
        <Flex bg={theme.colors.brand.background} p={4} color="white" alignItems="center">
            {/* Logo Image */}
            <Box onClick={() => navigate("/")} cursor="pointer">
                <Image src="/logo.png" alt="UT Web Helper Logo" boxSize="120px" />
            </Box>
            <Box ml="auto">
                <Button colorScheme="teal" variant="ghost" onClick={() => navigate("/")}>
                    Home
                </Button>
                {/* <Button colorScheme="teal" variant="ghost" onClick={() => navigate("/features")}>
                    Features
                </Button> */}
                <Button colorScheme="teal" variant="ghost" onClick={() => navigate("/howto")}>
                    How-To
                </Button>
                <Button colorScheme="teal" variant="ghost" onClick={() => navigate("/about")}>
                    About
                </Button>
                <Button colorScheme="teal" variant="ghost" onClick={() => navigate("/privacy")}>
                    Privacy Policy
                </Button>
                <Button colorScheme="teal" variant="ghost" onClick={() => (window.location.href = "https://chromewebstore.google.com/detail/ut-web-helper/afhliekobdpikegigkiihhoggiajijpf")}>
                    Install Extension
                </Button>

                <Menu>
                    {currentUser && renderSubscriptionBadge()}
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                        {currentUser ? <UserProfile /> : <Avatar size="md" />}
                    </MenuButton>

                    <MenuList>
                        {!currentUser ? (
                            <>
                                <MenuItem onClick={() => navigate("/login")}>Login</MenuItem>
                                <MenuItem onClick={() => navigate("/signup")}>Sign Up</MenuItem>
                            </>
                        ) : (
                            <>
                                <MenuItem
                                    onClick={() => {
                                        setSubscriptionStatus({ status: "inactive", validUntil: null }); // Reset to a default state
                                        auth.signOut();
                                        navigate("/");
                                    }}
                                >
                                    Logout
                                </MenuItem>
                            </>
                        )}
                    </MenuList>
                </Menu>
            </Box>
        </Flex>
    );
}

export default Header;
