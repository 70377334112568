import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Importing components and context
import Header from "./Header";
import MainContent from "./MainElements/MainContent";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import About from "./About/about";
import Login from "./Login/Login";
import { AuthProvider } from "./AuthContext";

import "./App.css";
import { theme } from "./theme";
import SignUp from "./Auth/SignUp";
import EmailVerification from "./EmailVerification";
import Checkout from "./Checkout/Checkout";
import useTokenRefresh from "./usertTokenRefresh";
import Success from "./Checkout/Success";
import { setupAxiosInterceptors } from "./services/apiInterceptor";
import { logoutUser } from "./services/authService";
import CookieConsent from 'react-cookie-consent';
import HowTo from "./Howto/HowTo";
import Features from "./Features/Features";
import Policy from "./PrivacyPolicy/Policy";
import FooterSmallCentered from "./Footer";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);


const App = () => {
    useTokenRefresh();
    useEffect(() => {
        setupAxiosInterceptors(logoutUser);
    }, []);

    return (
        <Elements stripe={stripePromise}>
            <ChakraProvider theme={theme}>
                <AuthProvider>
                    {" "}
                    {/* Wrapping the application with AuthProvider */}
                    <Router>
                        <Header />
                        <Routes>
                            <Route path="/" element={<MainContent />} />
                            <Route path="/privacy" element={<Policy />} />
                            <Route path="/howto" element={<HowTo />} />
                            {/* <Route path="/features" element={<Features />} /> */}
                            <Route path="/about" element={<About />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/signup" element={<SignUp />} />
                            <Route path="/checkout" element={<Checkout />} />
                            <Route path="/email-verification" element={<EmailVerification />} />
                            <Route path="/success" element={<Success />} />
                            {/* Add more routes as needed */}
                        </Routes>
                        <FooterSmallCentered></FooterSmallCentered>
                    </Router>
                </AuthProvider>
            </ChakraProvider>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="utwhcookie"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience. <span style={{ fontSize: "10px" }}>If you do not accept please leave the site</span>
            </CookieConsent>
        </Elements>
    );
};

export default App;
