import { Container, Heading, Box, SimpleGrid, AspectRatio, Text, Link, VStack, Button } from "@chakra-ui/react";
import React from "react";

function HowTo() {
    return (
        <Container maxW="container.md" mt={10}>
            <Heading as="h1" mb={6} textAlign="center">
                How To Use Our Tool
            </Heading>
            <Text fontSize="lg" mb={10} textAlign="center">
                Here are some helpful videos to guide you through using our tool effectively. Watch the tutorials or visit the full playlist for a comprehensive overview.
                Videos were recorded on EA FC 24 but it works same on newest version.
            </Text>
            <SimpleGrid columns={[1, 2]} spacing={10} mb={10}>
                <Box>
                    <AspectRatio ratio={16 / 9}>
                        <iframe
                            src="https://www.youtube.com/embed/KKi6fmHtczs?si=jvw3HKV9WTulVhzD"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </AspectRatio>
                    <Text mt={2} textAlign="center" fontWeight="bold">Video 1: How to Login and unlock premium features</Text>
                </Box>
                <Box>
                    <AspectRatio ratio={16 / 9}>
                        <iframe
                            src="https://www.youtube.com/embed/hraUQgJZlL4"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </AspectRatio>
                    <Text mt={2} textAlign="center" fontWeight="bold">Video 2: How to setup automatic relisting</Text>
                </Box>
                <Box>
                    <AspectRatio ratio={16 / 9}>
                        <iframe
                            src="https://www.youtube.com/embed/2gS4v1wPqBQ"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </AspectRatio>
                    <Text mt={2} textAlign="center" fontWeight="bold">Video 3: Setting expected profit</Text>
                </Box>
                <Box>
                    <AspectRatio ratio={16 / 9}>
                        <iframe
                            src="https://www.youtube.com/embed/N5kj9pa0ag8"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </AspectRatio>
                    <Text mt={2} textAlign="center" fontWeight="bold">Video 4: Snipping</Text>
                </Box>
                <Box>
                    <AspectRatio ratio={16 / 9}>
                        <iframe
                            src="https://www.youtube.com/embed/3F6oumxhW7U"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                        ></iframe>
                    </AspectRatio>
                    <Text mt={2} textAlign="center" fontWeight="bold">Video 5: Using market search filters</Text>
                </Box>
            </SimpleGrid>
            <VStack spacing={4} alignItems="center">
                <Link href="https://www.youtube.com/watch?v=j0Q2BCt2slg&list=PLpty5RgdTHgQv3F3IjdCOOv9VYo6ynTQf" isExternal>
                    <Button colorScheme="teal" size="lg">Watch Full Playlist on YouTube</Button>
                </Link>
            </VStack>
        </Container>
    );
}

export default HowTo;
